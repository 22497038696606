import styled from "styled-components";
import { Link as linkR } from "react-router-dom";

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  
  @media screen and (max-width: 478px) {
    margin-top:0;
    width: 100%;
  }
`;
export const PriceWrapper = styled.div`
  margin: 20px;
  background-color: rgb(209, 209, 209);
  padding: 20px;
  width: 380px;
  border-radius: 30px;
  background: linear-gradient(145deg, #e6e6e6, #e0e0e0);
  box-shadow: 6px 6px 12px #b2b2b2, -6px -6px 12px #f0f0f0;
  transition: 0.5s;

  :hover {
    text-shadow: 0px 1px 12px rgba(66, 68, 90, 1);
    border-radius: 30px;
    background: #e6e6e6;
    box-shadow:  20px 20px 40px #c4c4c4,
             -20px -20px 40px #ffffff;
  @media screen and (max-width: 400px) {
    height: auto;
    font-size: 11px;
    padding:10px;
  }

`;
export const ContainerOne = styled.div``;
export const Wymiar = styled.h4`
  padding-top: 40px;
  font-size: 1.3em;
  font-weight: bold;
  text-transform: uppercase;
  @media screen and (max-width: 400px) {
    padding-top: 10px;
  }
`;
export const WymiarDane = styled.p`
  padding-top: 20px;
  font-size: 1.4em;
`;
export const ContainerTwo = styled.div``;
export const StandardowaCena = styled.h4`
  padding-top: 40px;
  font-size: 1.3em;
  font-weight: bold;
  text-transform: uppercase;
`;
export const StandardowaCenaDane = styled.p`
  font-size: 1.4em;
  padding-top: 20px;
`;
export const ContainerThree = styled.div``;
export const CenaBoki = styled.h4`
  padding-top: 40px;
  font-size: 1.3em;
  font-weight: bold;
  text-transform: uppercase;
`;
export const CenaBokiDane = styled.p`
  font-size: 1.4em;
  padding-top: 20px;
`;
export const ContainerFour = styled.div``;
export const Rasy = styled.h4`
  padding-top: 40px;
  font-size: 1.3em;
  font-weight: bold;
  text-transform: uppercase;
`;
export const RasyDane = styled.p`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.4em;
  height: 120px;
`;

export const Button = styled(linkR)`
  font-size: 1.2em;
  font-weight: bolder;
  text-decoration: none;
  color: black;
`;
export const ButtonWrap = styled.div`
  font-size: 1.2em;
  font-weight: bolder;
  color: rgb(20, 20, 20);
  width: 60%;
  height: 45px;
  line-height: 45px;
  margin: 0 auto 20px;
  text-decoration: none;
  transition: 0.5s;

  border-radius: 15px;
  background: #e6e6e6;
  box-shadow: 6px 6px 12px #c4c4c4, -6px -6px 12px #ffffff;
  :hover {
    font-size: 1.3em;
    text-shadow: 0px 4px 6px rgba(66, 68, 90, 1);
    border-radius: 15px;
    background: #e6e6e6;
    box-shadow:  20px 20px 40px #c4c4c4,
             -20px -20px 40px #ffffff;
    }
  :active {
    border-radius: 15px;
  background: #e6e6e6;
  box-shadow: 6px 6px 12px #c4c4c4, -6px -6px 12px #ffffff;
  }

`;
