import React, { useState } from "react";
import ScrollToTop from "../components/scrollToTop";
import Footer from "../components/Footer";
import SidebarOthers from "../components/SidebarOthers";
import NavbarOthers from "../components/NavbarOthers";
import About from "../components/About";

const AboutPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <SidebarOthers isOpen={isOpen} toggle={toggle} />
      <NavbarOthers toggle={toggle} />
      <ScrollToTop />
      <About />
      <Footer />
    </>
  );
};

export default AboutPage;
