import React from "react";
import { Zoom } from "react-awesome-reveal";
import {
  InfoSection,
  TitleTop,
  MainContainer,
  PayList,
} from "../StyleElements";

const Delivery = () => {
  return (
    <>
      <Zoom>
        <MainContainer>
          <InfoSection>
            <TitleTop>Dostawa</TitleTop>
            <PayList>
              Nasze klatki dostarczamy na paletach już gotowe /
              sklejone. Wysyłamy je na całą Polskę dostępnymi kurierami na naszym
              rynku np: DPD , DHL , FedEx. Cena takiej usługi to 200 zł i
              należy ją doliczyć do ceny Klatki.
            </PayList>
          </InfoSection>
        </MainContainer>
      </Zoom>
    </>
  );
};

export default Delivery;
