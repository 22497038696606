import React from "react";
import { Zoom } from "react-awesome-reveal";
import {
  MainContainer,
  InfoSection,
  PayList,
  Title,
  TitleTop,
} from "../StyleElements";

const Peyments = () => {
  return (
    <>
      <Zoom>
        <MainContainer>
          <InfoSection>
            <TitleTop>Płatności</TitleTop>
            <Title>Na tą chwilę zamówienia składamy następująco:</Title>
            <PayList>
              Podczas rozmowy telefonicznej uzgadniamy wymiar / kolor / model
              Następnie informujemy zamawiającego o cenie końcowej klatki
              kennelowej odrazu podczas rozmowy albo sms-em w którym podaję
              wszystkie dane dotyczące numeru konta na które należy wpłacić
              zaliczkę. W momencie gdy klatka jest gotowa - robię jej zdjęcia i
              wysyłam
            </PayList>
            <PayList>Czekamy na resztę pieniędzy i zamawiamy kuriera.</PayList>
            <Title>Raty</Title>
            <PayList>
              Aktualnie nie prowadzimy sprzedaży ratalnej, jesli ktoś ma
              potrzebę zakupu ratalnego to na życzenie wystawiamy ogłoszenie na
              Allegro - ale niestety wiąże się to z wyzszą ceną (prowizja
              allegro)
            </PayList>
          </InfoSection>
        </MainContainer>
      </Zoom>
    </>
  );
};

export default Peyments;
