import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import {ReactComponent as Logo } from '../../images/logo.svg'

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavLinksS,
  NavBtn,
} from "./NavbarElements";

function Navbar({ toggle }) {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 280) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
    <IconContext.Provider value={{ color: '#fff'}}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={toggleHome}>
            GetLoft
            <Logo/>
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="klatki"
                smooth={true}
                duration={50}
                spy={true}
                // exact="true"
                offset={-80}
              >
                Klatki
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="bufety"
                smooth={true}
                duration={50}
                spy={true}
                // exact="true"
                offset={-80}
              >
                Bufety
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="legowiska"
                smooth={true}
                duration={50}
                spy={true}
                // exact="true"
                offset={-80}
              >
                Legowiska
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinksS
                to="/galeria/klatki-kennelowe-1200x600"
                smooth={true}
                duration={50}
                spy={true}
                // exact="true"
                offset={-80}
              >
                Galeria
              </NavLinksS>
            </NavItem>
            <NavItem>
              <NavLinksS
                to="/about"
                // smooth={true}
                // duration={50}
                // spy={true}
                // exact="true"
                // offset={-80}
              >
                O Nas
              </NavLinksS>
            </NavItem>
            <NavItem>
              <NavLinksS
                to="/klatki-kennelowe-cennik"
                // smooth={true}
                // duration={50}
                // spy={true}
                // exact="true"
                // offset={-80}
              >
                Zamów
              </NavLinksS>
            </NavItem>
          </NavMenu>
          <NavBtn>
              <Logo 
              width="80"
              height="80"
              background-color="white"
              />
            
          </NavBtn>
        </NavbarContainer>
      </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
