import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLinkS,
} from "./PriceSidebarElements";

function PriceSidebar({isOpen, toggle}) {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon></CloseIcon>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLinkS onClick={toggle} to="/" exact="true" >Home</SidebarLinkS>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

export default PriceSidebar;
