import React from 'react';
import "./App.css";
import { BrowserRouter as Router , Switch, Route} from "react-router-dom";
import Home from './pages';
import GalleryPage from './pages/galeria';
import GalleryPageTwo from './pages/galeriaTwo';
import GalleryPageThree from './pages/galeriaThree';
import GalleryPageFour from './pages/galeriaFour';
import GalleryPageFive from './pages/galeriaFive';
import GalleryPageSix from './pages/galeriaSix';
import PrivacyPage from './pages/privacy';
import PlatnosciPage from './pages/platnosci';
import ReklamacjePage from './pages/reklamacje';
import RegulaminPage from './pages/regulamin';
import DostawaPage from './pages/dostawa';
import CennikPage from './pages/cennik';
import SoonPage from './pages/soonPage';
import DeadEndPage from './pages/deadEnd';
import AboutPage from './pages/about';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/galeria/klatki-kennelowe-z-szufladami" component={GalleryPage} exact />
        <Route path="/galeria/klatki-kennelowe-1400x600" component={GalleryPageSix} exact />
        <Route path="/galeria/klatki-kennelowe-1200x600" component={GalleryPageTwo} exact />
        <Route path="/galeria/klatki-kennelowe-1000x600" component={GalleryPageThree} exact />
        <Route path="/galeria/klatki-kennelowe-800x600" component={GalleryPageFour} exact />
        <Route path="/galeria/klatki-kennelowe-duo" component={GalleryPageFive} exact />
        <Route path="/about" component={AboutPage} exact />
        <Route path="/privacy" component={PrivacyPage} exact />
        <Route path="/platnosci" component={PlatnosciPage} exact />
        <Route path="/reklamacje" component={ReklamacjePage} exact />
        <Route path="/regulamin" component={RegulaminPage} exact />
        <Route path="/dostawa" component={DostawaPage} exact />
        <Route path="/klatki-kennelowe-cennik" component={CennikPage} exact />
        <Route path="/soon" component={SoonPage} exact />
        <Route path="*" component={DeadEndPage} exact />
      </Switch>
    </Router>
  );
}

export default App;
