import styled from "styled-components";

export const MainContainer = styled.div`
  // display: flex;
  // flex-direction: coulumn;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height: 70%;
  height: auto;
  width: 70%;
  margin: 70px auto;
  text-align: justify;
  font-size: 1.2rem;
  @media screen and (max-width: 768px) {
    width: 90%;
    min-height: 60vh;
  }
    
`;

export const AboutDecription = styled.h3`
  margin-top: 20px;
  font-size: 18px;
  font-weight: 100;
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
    
`;

export const HeroAboutImage = styled.img`
  margin-top: 50px;
  width: 100%;
  height: auto;
  background:#fff;
  opacity: 0.9;
`;
