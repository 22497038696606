import logo1 from "../../images/klatka4.jpg";
import logo2 from "../../images/stojaki.jpg";
import logo3 from "../../images/legowisko.jpg";
import logo4 from "../../images/klatka3.jpg";

export const homeObjOne = {
  id: "klatki",
  lightBg: false,
  lightText: true,
  lightTextDest: true,
  topLine: "Produkty Premium",
  headLine: "Klatki Kenelowe",
  description:
    "Klatki kenelowe, znane również jako boksy dla psów, są praktycznym i bezpiecznym rozwiązaniem, które zapewnia zwierzęciu swoje własne miejsce, jednocześnie umożliwiając właścicielowi utrzymanie kontroli nad jego bezpieczeństwem i dobrym samopoczuciem. Te struktury służą różnym celom, takim jak zapewnienie bezpieczeństwa w czasie podróży, treningu czy zabezpieczenia psa w domu. My oferujemy wam funkcionalność klatki i komody, w takim połączeniu może taka klatka urozmaicić wnętrza i zapewnić spokój pupila.",
  buttonLabel: "Zamawiam",
  linkTo: "/cennik",
  imgStart: true,
  img: logo1,
  alt: "Biała klatka kennelowa krzyżami",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "bufety",
  lightBg: true,
  lightText: false,
  lightTextDest: false,
  topLine: "Z najwyższej półki",
  headLine: "Stojaki na miski",
  description:
    "Stojak na miski dla psa to praktyczne i funkcjonalne rozwiązanie, które nie tylko ułatwia karmienie czworonoga, ale także przyczynia się do utrzymania porządku w miejscu, gdzie zwierzak spożywa posiłki. Ten rodzaj udogodnienia jest szczególnie przydatny dla właścicieli psów, którzy chcą zapewnić swoim pupilom wygodne i higieniczne warunki podczas jedzenia. Każda miseczka może pełnić funkcję dla wody lub karmy, co pozwala oddzielić jedzenie od napoju. Ponadto, stojak zapewnia odpowiednią wysokość misek, dostosowaną do rozmiaru psa, co może być istotne dla jego wygody podczas jedzenia.Przy wyborze stojaka na miski dla psa warto zwrócić uwagę na jego stabilność, łatwość czyszczenia oraz dostosowanie do wielkości i potrzeb konkretnego zwierzaka. Dbałość o wygodę i zdrowie pupila podczas jedzenia to ważny element codziennego dbania o jego dobre samopoczucie.",
  buttonLabel: "Zamawiam",
  linkTo: "/soon",
  imgStart: false,
  img: logo2,
  alt: "Potrójne stojaki na miski",
  dark: false,
  primary: false,
  darkText: true,
};
export const homeObjThree = {
  id: "legowiska",
  lightBg: false,
  lightText: true,
  lightTextDest: true,
  topLine: "Aby dobrze spać",
  headLine: "Legowiska",
  description:
    "Legowiska dla psów to nie tylko wygodne miejsce do spania dla czworonoga, ale również ważny element zapewniający mu komfort i bezpieczeństwo. W dzisiejszych czasach legowiska dostępne są w różnorodnych kształtach, rozmiarach i stylach, co pozwala właścicielom dopasować je do potrzeb swoich pupili, a jednocześnie stanowią estetyczny element wnętrza domu.Podczas wyboru legowiska dla psa warto zwrócić uwagę na jego rozmiar, materiał z jakiego zostało wykonane oraz dostosowanie do specyficznych potrzeb rasy czy wieku psa. Legowisko powinno być odpowiednio duże, aby pies mógł się swobodnie rozciągnąć.",
  buttonLabel: "Zamawiam",
  linkTo: "/soon",
  imgStart: true,
  img: logo3,
  alt: "brązowe legowisko dla psa",
  dark: true,
  primary: true,
  darkText: false,
};
export const homeObjFour = {
  id: "galeria",
  lightBg: true,
  lightText: false,
  lightTextDest: false,
  topLine: "Galeria",
  headLine: "Zapraszamy do obejrzenia naszych realizacji",
  description:
    "Znajdziecie tutaj zdjęcia naszych realizacji z podziałem na wielkości klatek kennelowych, zobaczycie tutaj nie tylko kształty ale róznież różne kolory naszych realizacji.",
  buttonLabel: "Do Galerii !!",
  linkTo: "/galeria/klatki-kennelowe-1200x600",
  imgStart: false,
  img: logo4,
  alt: "Brązowa drewniana klatka kennelowa",
  dark: false,
  primary: false,
  darkText: true,
};
