import React, { useState } from "react";
import ScrollToTop from '../components/scrollToTop'
import Footer from "../components/Footer";
import NavbarOthers from "../components/NavbarOthers";
import SidebarOthers from "../components/SidebarOthers";
import Peyments from "../components/Peyments"

const PlatnosciPage = () => {
    const [isOpen, setIsOpen] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen);
  };



    return (
        <>
        <SidebarOthers isOpen={isOpen} toggle={toggle} />
        <NavbarOthers toggle={toggle} />
        <Peyments />
        <ScrollToTop />
        <Footer />
        </>
    )
  }

export default PlatnosciPage
