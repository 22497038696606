import { Carousel } from 'react-carousel-minimal';
import image1 from "../../images/1400/1400 (1).jpg";
import image2 from "../../images/1400/1400 (2).jpg";
import image3 from "../../images/1400/1400 (3).jpg";
import image4 from "../../images/1400/1400 (4).jpg";
import image5 from "../../images/1400/1400 (5).jpg";
import image6 from "../../images/1400/1400 (6).jpg";
import image7 from "../../images/1400/1400 (7).jpg";
import image8 from "../../images/1400/1400 (8).jpg";

function Galeria() {
 const data = [
    {
      image: image1,
      caption: "Duża drewniana klatka kennelowa dla psa w kolorze ciemnego drewna",
    },
    {
      image: image2,
      caption: "Duża klatka kennelowa w kolorzee palisandru dla dużego psa",
    },
    {
      image:image3,
      caption: "Dreaniana klatka kennelowa w kolorze nauralnej sosny",
    },
    {
      image: image4,
      caption: "Biała klatka kennelowa c ciemnym blatem w kolorze ciemnego dębu",
    },
    {
      image: image5,
      caption: "Duża drewniana klatka kennelowa z zabudowanymi bokami w kolorze ciemnego drewna",
    },
    {
      image: image6,
      caption: "Duża drewniana klatka klatka kennelowa w czarnym kolorze",
    },
    {
      image: image7,
      caption: "Drewniana klatka kennelowa w kolorze palisandru dla dużego psa",
    },
    {
      image: image8,
      caption: "Komodoklatka dla dużego psa w kolorze dębu",
    },
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App">
      <div style={{ 
        textAlign: "center",
        marginTop: "100px",
        marginBottom: "300px"
        }}>
        <h2
        style={{
          textAlign: "center",
          maxWidth: "850px",
          maxHeight: "500px",
          margin: "50px auto ",
          paddingTop: "50px"
        }}>1400x600</h2>
        <p>Ze względu na zapotrzebowanie na duże klatki podwójne - dla dwóch i więcej piesków, wyszliśmy z propozycją podwójnego kenela - DuolKennel.</p>
        <p>Proponujemy rozwiązanie dwóch klatek ze względu na to, że klatka podwójna w jednej bryle stanowi wyzwanie w sferze jej dostarczenia oraz ciężaru.</p>
        <p>Nie ukrywajmy - klatki są wykonane z litego / klejonego drewna oraz metalu, stąd nasza propozycja.</p>
        <p>Są one projektowane w taki spotób by na łączniu nie było progu - jest to według nas bardziej estetyczne i daje te kilka cm więcej dla pieska.</p>
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
            data={data}
            time={5000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "100px auto ",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Galeria;
