import React, { useState } from "react";
import ScrollToTop from "../components/scrollToTop";
import Footer from "../components/Footer";
import PriceSidebar from "../components/PriceSidebar";

import NavbarPrize from "../components/NavbarPrize";
import PricesTable from "../components/PricesTable";
import {
  priceObjOne,
  priceObjTwo,
  priceObjThree,
  priceObjFour,
} from "../components/PricesTable/data";
import { addons } from "../components/PricesAddons/data";
import PriceAddons from "../components/PricesAddons";

const CennikPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <PriceSidebar isOpen={isOpen} toggle={toggle} />
      <NavbarPrize toggle={toggle} />
      <ScrollToTop />
      <h2 className="pricesTitle">
        To jest cennik naszych klatek , pomoże on wam wycenić klatkę kennelową
        we własnym zakresie. W celu zamówienia prosimy o kontakt poprzez rozmowę
        telefoniczną / sms / email będź messengera. Zapraszamy !!!
      </h2>
      <div className="pricesFlex">
        <PricesTable {...priceObjOne} />
        <PricesTable {...priceObjTwo} />
        <PricesTable {...priceObjThree} />
        <PricesTable {...priceObjFour} />
      </div>
      <PriceAddons {...addons} />
      <Footer />
    </>
  );
};

export default CennikPage;
