import React, { useState } from "react";
import ScrollToTop from "../components/scrollToTop";
import Footer from "../components/Footer";
import GaleriaFive from "../components/GaleriaFive";
import GallerySidebar from "../components/GallerySidebar";

import GalleryNavbar from "../components/GalleryNavbar";

const GalleryPageFive = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <GallerySidebar isOpen={isOpen} toggle={toggle} />
      <GalleryNavbar toggle={toggle} />
      <ScrollToTop />
      <GaleriaFive /> 
      <Footer />
    </>
  );
};

export default GalleryPageFive
