export const priceObjOne = {
    id : '800',
    sizeName: 'Wymiar',
    size:'800 x 600 x 640 mm' ,
    priceOneName: 'Standardowa Cena z bokami ażurowymi',
    priceOneData: '1150 PLN*',
    priceTwoName:'Z bokami zabudowanymi',
    priceTwoData:'+ 200 PLN',
    rasaName: 'Rasy Małe**',
    rasaDescription: 'Chihuahua, Jamnik, Yorkshire Terrier, Pudel miniaturowy.',
    linkTo:'/galeria/klatki-kennelowe-800x600',

};
export const priceObjTwo = {
    id : '1000',
    sizeName: 'Wymiar',
    size:'1000 x 600 x 700 mm' ,
    priceOneName: 'Standardowa Cena z bokami ażurowymi',
    priceOneData: '1500 PLN*',
    priceTwoName:'Z bokami zabudowanymi',
    priceTwoData:'+ 200 PLN',
    rasaName: 'Rasy Średnie**',
    rasaDescription: 'Bullterrier, Pudel, Cocker Spaniel, Szpic, Shih tzu',
    linkTo:'/galeria/klatki-kennelowe-1000x600'

};
export const priceObjThree = {
    id : '1200',
    sizeName: 'Wymiar',
    size:'1200 x 600 x 700 mm' ,
    priceOneName: 'Standardowa Cena z bokami ażurowymi',
    priceOneData: '1900 PLN*',
    priceTwoName:'Z bokami zabudowanymi',
    priceTwoData:'+ 200 PLN',
    rasaName: 'Rasy Średnie większe**',
    rasaDescription: 'Amstaff, Owczarek Niemiecki.',
    linkTo:'/galeria/klatki-kennelowe-1200x600',

};
export const priceObjFour = {
    id : '1400',
    sizeName: 'Wymiar',
    size:'1400 x 600 x 700 mm' ,
    priceOneName: 'Standardowa Cena z bokami ażurowymi',
    priceOneData: '2300 PLN*',
    priceTwoName:'Z bokami zabudowanymi',
    priceTwoData:'+ 300 PLN',
    rasaName: 'Rasy Duże**',
    rasaDescription: 'Dog Niemiecki, Berneńczyk.',
    linkTo:'/galeria/klatki-kennelowe-1400x600',

};

