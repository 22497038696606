import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLinkS,
} from "./GallerySidebarElements";

function GallerySidebar({isOpen, toggle}) {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon></CloseIcon>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLinkS onClick={toggle} to="/klatki-kennelowe-cennik" exact="true" >Cennik</SidebarLinkS>
          <SidebarLinkS onClick={toggle} to="/galeria/klatki-kennelowe-1400x600" exact="true" >1400x600</SidebarLinkS>
          <SidebarLinkS onClick={toggle} to="/galeria/klatki-kennelowe-1200x600" exact="true" >1200x600</SidebarLinkS>
          <SidebarLinkS onClick={toggle} to="/galeria/klatki-kennelowe-1000x600" exact="true">1000x600</SidebarLinkS>
          <SidebarLinkS onClick={toggle} to="/galeria/klatki-kennelowe-800x600" exact="true">800x600</SidebarLinkS>
          <SidebarLinkS onClick={toggle} to="/galeria/klatki-kennelowe-duo" exact="true">Podwójne</SidebarLinkS>
          <SidebarLinkS onClick={toggle} to="/galeria/klatki-kennelowe-z-szufladami" exact="true">Z Szufladami</SidebarLinkS>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

export default GallerySidebar;
