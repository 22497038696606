import React from "react";
import { Zoom } from "react-awesome-reveal";
import {
  Container,
  PrivacyDiv,
  CookiesDiv,
  Title,
  Content,
  Description,
} from "./PrivacyElements";

const Privacy = () => {
  return (
    <>
      <Container>
        <Zoom triggerOnce="true">
          <PrivacyDiv>
            <Title>Polityka prywatności serwisu www.Getloft.pl</Title>
            <Content>
              <Description>1. Informacje ogólne</Description>
              <Description>
                Niniejsza Polityka Prywatności określa zasady przetwarzania i
                ochrony danych osobowych przekazanych przez Użytkowników w
                związku z korzystaniem przez nich z usług oferowanych poprzez
                Serwis Getloft.pl
              </Description>

              <Description>
                2. Administratorem danych osobowych zawartych w serwisie jest
                Anna Fujarska
              </Description>

              <Description>
                3. W trosce o bezpieczeństwo powierzonych nam danych
                opracowaliśmy wewnętrzne procedury i zalecenia, które mają
                zapobiec udostępnieniu danych osobom nieupoważnionym.
                Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z
                odpowiednimi aktami prawnymi - ustawą o ochronie danych
                osobowych, ustawą o świadczeniu usług drogą elektroniczną, a
                także wszelkiego rodzaju aktach wykonawczych i aktach prawa
                wspólnotowego.
              </Description>

              <Description>
                {" "}
                4. Dane Osobowe przetwarzane są na podstawie zgody wyrażanej
                przez Użytkownika oraz w przypadkach, w których przepisy prawa
                upoważniają Administratora do przetwarzania danych osobowych na
                podstawie przepisów prawa lub w celu realizacji zawartej
                pomiędzy stronami umowy.
              </Description>

              <Description>
                5. Serwis realizuje funkcje pozyskiwania informacji o
                użytkownikach i ich zachowaniach w następujący sposób:
              </Description>

              <Description>
                a) poprzez dobrowolnie wprowadzone w formularzach informacje
              </Description>

              <Description>
                b) poprzez gromadzenie plików “cookies” [patrz polityka plików
                “cookies”].
              </Description>

              <Description>
                6. Serwis zbiera informacje dobrowolnie podane przez
                użytkownika.dane podane w formularzu są przetwarzane w celu
                wynikającym z funkcji konkretnego formularza np. w celu
                dokonania procesu obsługi kontaktu informacyjnego.
              </Description>
              <Description>
                7. Dane osobowe pozostawione w serwisie nie zostaną sprzedane
                ani udostępnione osobom trzecim, zgodnie z przepisami Ustawy o
                ochronie danych osobowych.
              </Description>

              <Description>
                8. Do danych zawartych w formularzu przysługuje wgląd osobie
                fizycznej, która je tam umieściła. Osoba ta ma również praw do
                modyfikacji i zaprzestania przetwarzania swoich danych w
                dowolnym momencie.
              </Description>

              <Description>
                9. Zastrzegamy sobie prawo do zmiany w polityce ochrony
                prywatności serwisu, na które może wpłynąć rozwój technologii
                internetowej, ewentualne zmiany prawa w zakresie ochrony danych
                osobowych oraz rozwój naszego serwisu internetowego. O wszelkich
                zmianach będziemy informować w sposób widoczny i zrozumiały.
              </Description>

              <Description>
                10. W Serwisie mogą pojawiać się linki do innych stron
                internetowych. Takie strony internetowe działają niezależnie od
                Serwisu i nie są w żaden sposób nadzorowane przez serwis
                getloft.pl . Strony te mogą posiadać własne polityki dotyczące
                prywatności oraz regulaminy, z którymi zalecamy się zapoznać. W
                razie wątpliwości co któregokolwiek z zapisów niniejszej
                polityki prywatności jesteśmy do dyspozycji - nasze dane znaleźć
                można w zakładce - KONTAKT.
              </Description>
            </Content>
          </PrivacyDiv>
        </Zoom>
        <Zoom triggerOnce="true">
          <CookiesDiv>
            <Title> Polityka plików “cookies” serwisu GetLoft</Title>
            <Content>
              <Description>
                1. Poprzez piki “cookies” należy rozumieć dane informatyczne
                przechowywane w urządzeniach końcowych użytkowników,
                przeznaczone do korzystania ze stron internetowych. W
                szczególności są to pliki tekstowe, zawierające nazwę strony
                internetowej, z której pochodzą, czas przechowywania ich na
                urządzeniu końcowym oraz unikalny numer.
              </Description>

              <Description>
                2. Serwis nie zbiera w sposób automatyczny żadnych informacji, z
                wyjątkiem informacji zawartych w plikach cookies.
              </Description>

              <Description>
                3. Pliki cookies przeznaczone są do korzystania ze stron
                serwisu. Operator wykorzystuje te pliki do:
              </Description>

              <Description>
                a) możliwości logowania i utrzymywania sesji użytkownika na
                każdej kolejnej stronie serwisu
              </Description>

              <Description>
                b) dopasowania zawartości strony internetowej do indywidualnych
                preferencji użytkownika, przede wszystkim pliki te rozpoznają
                jego urządzenie, aby zgodnie z jego preferencjami wyświetlić
                stronę
              </Description>

              <Description>
                c) do tworzenia anonimowych statystyk z wyłączeniem możliwości
                identyfikacji użytkownika.{" "}
              </Description>

              <Description>
                4. Pliki cookies wykorzystywane przez partnerów operatora strony
                internetowej, w tym w szczególności użytkowników strony
                internetowej, podlegają ich własnej polityce prywatności.
              </Description>
              <Description>
                5. W trosce o bezpieczeństwo powierzonych nam danych
                opracowaliśmy wewnętrzne procedury i zalecenia, które mają
                zapobiec udostępnieniu danych osobom nieupoważnionym.
                Kontrolujemy ich wykonywanie i stale sprawdzamy ich zgodność z
                odpowiednimi aktami prawnymi - ustawą o ochronie danych
                osobowych, ustawą o świadczeniu usług drogą elektroniczną, a
                także wszelkiego rodzaju aktach wykonawczych i aktach prawa
                wspólnotowego
              </Description>

              <Description>
                6. Standardowo oprogramowanie służące do przeglądania stron
                internetowych domyślnie dopuszcza umieszczanie plików cookies na
                urządzeniu końcowym Użytkownika. Ustawienia te mogą zostać
                zmienione przez Użytkownika w taki sposób, aby blokować
                automatyczną obsługę “cookies” w ustawieniach przeglądarki
                internetowej bądź informować o ich każdorazowym przesłaniu na
                urządzenia użytkownika.
              </Description>

              <Description>
                7. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany
                ustawień dotyczących plików cookies. Szczegółowe informacje o
                możliwości i sposobach obsługi plików cookies dostępne są w
                ustawieniach oprogramowania (przeglądarki internetowej).
                Przykładowe opcje edytowania w popularnych przeglądarkach: -
                Mozilla Firefox: www.support.mozilla.org/pl/kb/ciasteczka -
                Internet Explorer: www.support.microsoft.com/kb/278835/pl -
                Google Chrome:
                www.support.google.com/chrome/bin/answer.py?hl=pl&answer=95647 -
                Safari:
                www.safari.helpmax.net/pl/oszczedzanie-czasu/blokowanie-zawartosci/
              </Description>

              <Description>
                8. Operator Serwisu informuje, że zmiany ustawień w przeglądarce
                internetowej użytkownika mogą uniemożliwić poprawne działanie
                Stron Internetowych.
              </Description>
            </Content>
          </CookiesDiv>
        </Zoom>
      </Container>
    </>
  );
};

export default Privacy;
