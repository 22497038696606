import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarLinkR,
} from "./SidebarElements";

function Sidebar({isOpen, toggle}) {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon></CloseIcon>
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink onClick={toggle} to="klatki">Klatki</SidebarLink>
          <SidebarLink onClick={toggle} to="bufety">Bufety</SidebarLink>
          <SidebarLink onClick={toggle} to="legowiska">Legowiska</SidebarLink>
          <SidebarLink onClick={toggle} to="galeria">Galeria</SidebarLink>
          <SidebarLinkR onClick={toggle} to="/klatki-kennelowe-cennik">Cennik</SidebarLinkR>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
}

export default Sidebar;
