export const addons = {
    span: 'Wysokość : ',
    description: ' Dopłata za zwiększenie wysokości wynosi 100PLN',
    span1: 'Głębokość : ',
    description1: ' Jak zauważyliście w standardzie nasze klatki majągłębokość 600 mm, najgłębsze klatki jakie produkujemy mają 800 mm głębokości. Każde zwiększenie głębokości ponad 600 mm skutkuje dopłatą w wysokości 150PLN.',
    span2: 'Szerokość : ',
    description2: ' Jeśli chodzi o szerokość klatki to najszersze jakie produkujemy to klatki o szerokości 1400mm i różnią się one ilością ramek na froncie - jest ich trzy zamiast dwóch.',
    span3: 'Rodzaj farby/bejcy : ',
    description3: ' W standardzie używamy farb akrylowych (biała,czarna) oraz lakierobejce - jeśli nie znajdą państwo interesującego koloru w naszej galerii możemy dobrać coś innego. Jesli chodzi o farby akrylowe to jest możliwość doboru koloru z mieszalnika i palety barw RAL niestety za dopłatą (indywidualnie - zależy od jakości wybranej farby).',
    span4: 'Sprzedaż :',
    description4: ' Aktualnie wszystkie zamówienia przyjmujemy wyłącznie drogą telefoniczną / sms , poprzez portal ogłoszeniowy OLX oraz na portalu Allegro - na którym można zakupić nasze produkty na RATY .',
    span5: 'Z szufladami/szafką : ',
    description5:'Klatki kenelowe z dodatkowymi funkcjami takimi jak szuflady oraz szafki są wyceniane indywidualnie, i zawsze są produkowane ściśle w konsultacji z klientem i na podstawie zaakceptowanego projektu.',
    span6:'Drzwi przesuwne : ',
    description6:'Wykonujemy również klatki z drzwiami przesuwnymi, za opłatą dodatkową 100 PLN.',
    span7:'* : ',
    description7:'Do podanych tutaj cen należy doliczyć cenę transportu, która aktualnie wynosi 200PLN.',
    span8:'** : ',
    description8:' Powyższe przykłady ras psów i rozmairów klatek są tylko orientacyjne , nie doradzamy w kwesti doboru wielkości klatek do piesków z tego względu, iż spotkaliśmy się z rozbieżnymi opiniami  uzyskanymi od behawiorystów.',
    

    
    };