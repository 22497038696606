import React from 'react'
import {
  Addons,
  Description,
  Span,
  AddonsWrapper,
} from "./PricesAddonsElements";

const PriceAddons = ({
  // id,
  description,
  span,
  description1,
  span1,
  description2,
  span2,
  description3,
  span3,
  description4,
  span4,
  description5,
  span5,
  description6,
  span6,
  description7,
  span8,
  description8,
  span7,
}) => {
  return (
    <>
      <AddonsWrapper>
        <Addons>
          <Description>
          <Span>{ span }</Span>
            {description}
          </Description>
          <Description>
          <Span>{ span1 }</Span>
            {description1}
          </Description>
          <Description>
          <Span>{ span2 }</Span>
            {description2}
          </Description>
          <Description>
          <Span>{ span3 }</Span>
            {description3}
          </Description>
          <Description>
          <Span>{ span4 }</Span>
            {description4}
          </Description>
          <Description>
          <Span>{ span5 }</Span>
            {description5}
          </Description>
          <Description>
          <Span>{ span6 }</Span>
            {description6}
          </Description>
          <Description>
          <Span>{ span7 }</Span>
            {description7}
          </Description>
          <Description>
          <Span>{ span8 }</Span>
            {description8}
          </Description>
        </Addons>
      </AddonsWrapper>
      ;
    </>
  );
};

export default PriceAddons;
