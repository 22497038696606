import styled from "styled-components";

export const AddonsWrapper = styled.div`
  width: 95%;
  margin: auto;
  line-height: 1.4;
  @media only screen and (min-width: 768px) {
    width: 90%;
    margin: 100px auto;
    font-size: 1.2em;
  }
`;

export const Addons = styled.div`
  width: 80%;
  margin:auto;
  @media only screen and (min-width: 768px) {
    width: 90%;
`;
export const Description = styled.p`
  margin: 20px auto;
  padding 10px 0;
`;
export const Span = styled.span`
  font-weight: bold;
`;
