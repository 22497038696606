import styled from "styled-components";

export const Container = styled.div`
  width: 70%;
  margin: auto;
  line-height:30px;

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;

export const PrivacyDiv = styled.div`
  margin-top: 100px;
`;

export const CookiesDiv = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
`;

export const Title = styled.h1`
  margin-top: 100px;
  padding-top: 50px;
  font-size: 1.5 rem;
  text-align: center;
  @media (min-width: 280px) and (max-width: 320px) {
    font-size: 20px;
  }
`;

export const Content = styled.div`
  margin-top: 100px;
  font-size: 1rem;
  @media (min-width: 280px) and (max-width: 320px) {
    margin-top: 30px;
  }
`;
export const Description = styled.p`
  margin-top: 18px;
  font-size: 1rem;
  text-align: justify;
  text-justify: inter-word;
  margin: auto;
  @media screen and (max-width: 768px) {
    width: 90%;
    font-size: 14px;
    overflow-wrap: break-word;
  }
  @media (min-width: 280px) and (max-width: 320px) {
    font-size: 12px;
    overflow-wrap: break-word;
  }
`;
