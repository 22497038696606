import { Carousel } from 'react-carousel-minimal';
import image1 from "../../images/800/800 (1).jpg";
import image2 from "../../images/800/800 (2).jpg";
import image3 from "../../images/800/800 (3).jpg";
import image4 from "../../images/800/800 (4).jpg";
import image5 from "../../images/800/800 (5).jpg";
import image6 from "../../images/800/800 (6).jpg";
import image7 from "../../images/800/800 (7).jpg";
import image8 from "../../images/800/800 (8).jpg";
import image9 from "../../images/800/800 (9).jpg";
import image10 from "../../images/800/800 (10).jpg";

function Galeria() {
 const data = [
    {
      image: image1,
      caption: "Mała drewniana klatka kennelowa z prętami poziomo",
    },
    {
      image: image2,
      caption: "Mała biała klatka kennelowa z krzyżami po bokach",
    },
    {
      image:image3,
      caption: "Mała biała drewniana klatka kennelowa",
    },
    {
      image: image4,
      caption: "Mała drewniana biała z czarnymi prętami klatka kennelowa",
    },
    {
      image: image5,
      caption: "Mała czarna klatka kennelowa z prętami poziomo",
    },
    {
      image: image6,
      caption: "Drewnianna klatka kennelowa z krzyżami po bokach i dębowym blatem i spodem dla małego psa",
    },
    {
      image: image7,
      caption: "Mała klatka kennelowa w kolorze ciemnego drewna",
    },
    {
      image: image8,
      caption: "Dreaniana biała komodoklatka kennelowa z krzyżami i dębowym blatem",
    },
    {
      image: image9,
      caption: "Biała drewniana klatka kennelowa na kółkach z przesuwnymi drzwiami",
    },
    {
      image: image10,
      caption: "Biała dreaniana prosta klatka kennelowa dla psa",
    },
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App">
      <div style={{ 
        textAlign: "center",
        marginTop: "100px",
        marginBottom: "300px",
        }}>
        <h2
        style={{
          textAlign: "center",
          maxWidth: "850px",
          maxHeight: "500px",
          margin: "50px auto ",
          paddingTop: "50px"
        }}>Klatki o szerokości 800mm</h2>
        <p>To te które wybieracie dla swoich najmniejszych psiaczków</p>
        <p>Małe i ustawne, pięknie wyglądają przy łóżku jako stolik nocny.</p>
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
            data={data}
            time={5000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "100px auto ",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Galeria;
