import React from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
} from "./NavbarElements";

function GalleryNavbar({ toggle }) {


  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav >
          <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
              GetLoft
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavLinks to="/klatki-kennelowe-cennik"  exact="true">
                  Cennik
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/galeria/klatki-kennelowe-1400x600"  exact="true">
                  1400x600
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/galeria/klatki-kennelowe-1200x600"  exact="true">
                  1200x600
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/galeria/klatki-kennelowe-1000x600" exact="true">
                  1000x600
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/galeria/klatki-kennelowe-800x600" exact="true">
                  800x600
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/galeria/klatki-kennelowe-duo" exact="true">
                  DuoKennel
                </NavLinks>
              </NavItem>
              <NavItem>
                <NavLinks to="/galeria/klatki-kennelowe-z-szufladami" exact="true">
                  Z szufladami
                </NavLinks>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default GalleryNavbar;