import React from "react";
import { Zoom } from "react-awesome-reveal";
import {
  AboutDecription,
  HeroAboutImage,
  MainContainer,
} from "./AboutElements";
import image1 from "../../images/Abouthero.jpg";
import { InfoSection, TitleTop } from "../StyleElements";

const About = () => {
  return (
    <>
      <Zoom>
        <MainContainer>
          <HeroAboutImage src={image1} alt="klatka kennelowa" type="image/jpg" />
          <InfoSection>
            <TitleTop>Get Loft</TitleTop>
            <AboutDecription>
              Witaj w świecie drewnianych klatek kennelowych dla psów, gdzie
              funkcjonalność spotyka się z elegancją i trwałością. Jesteśmy
              pasjonatami psów, a nasza firma powstała z miłości do
              czworonożnych przyjaciół.
            </AboutDecription>
            <AboutDecription>
              Nasza historia rozpoczęła się z prostą ideą: stworzyć klatki
              kennelowe, które nie tylko będą bezpiecznym schronieniem dla
              Twojego pupila, ale również staną się estetycznym dodatkiem do
              Twojego domu.
            </AboutDecription>
            <AboutDecription>
              W naszym warsztacie mistrzowskim łączymy tradycyjne rzemiosło z
              nowoczesnym podejściem do projektowania. Każda nasza klatka jest
              starannie wykonana z wysokiej jakości drewna, dbając o każdy
              detal, aby zapewnić komfort i bezpieczeństwo dla Twojego psa.
              Nieustannie dążymy do doskonałości, stale poszerzając naszą wiedzę
              i doskonaląc nasze umiejętności.
            </AboutDecription>
            <AboutDecription>
              Nasze klatki kennelowe to nie tylko miejsce odpoczynku dla psa,
              ale także element wyposażenia, który podkreśla charakter Twojego
              domu. Dlatego jeśli szukasz klatki kennelowej, która spełni
              wszystkie Twoje oczekiwania pod względem jakości, funkcjonalności
              i designu, to jesteś we właściwym miejscu. Daj swojemu psu to, na
              co zasługuje - wyjątkowe miejsce, w którym będzie czuł się jak w
              domu.
            </AboutDecription>
            <AboutDecription>
              Aby udowodnić Ci, że znamy sie na tym, wiedz że zajmujemy się
              hodowlą psów rasowych, i bardzo dobrze zdajemy sobie z tego sprawę
              jak ważne dla czworonoga jest jego własne "M". W stópce
              znajdziecie linka do naszej "hodowlanej stronki"
            </AboutDecription>
            <AboutDecription>
              Serdecznie was pozdrawiamy, zachęcamy do zakupów i życzymy miłego dnia.
            </AboutDecription>
            <AboutDecription>
              Zespół Get Loft
            </AboutDecription>
            <AboutDecription></AboutDecription>
          </InfoSection>
        </MainContainer>
      </Zoom>
    </>
  );
};

export default About;
