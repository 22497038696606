import React from "react";
import { Zoom } from "react-awesome-reveal";
import {
  InfoSection,
  TitleTop,
  MainContainer,
  PayList,
} from "../StyleElements";

const DeadEnd = () => {
  return (
    <>
      <Zoom>
        <MainContainer>
          <InfoSection>
            <TitleTop>404</TitleTop>
            <PayList>
             Strona nie istnieje
            </PayList>
          </InfoSection>
        </MainContainer>
      </Zoom>
    </>
  );
};

export default DeadEnd;
