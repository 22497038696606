import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.footer`
  background-color: #262626;
  height: auto;
  scroll-snap-align: start;
  scroll-snap-stop: always;
`;
export const FooterWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
  justify-content: space-between;
  height: auto;
  // background-color:pink;
  @media screen and (max-width: 420px) {
    align-content: space-between;
  }
`;
export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  @media screen and (max-width: 420px) {
    padding-top: 32px;
    margin-top: 100px;
  }
`;
export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;
export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  text-align: left;
  width: auto;
  color: #fff;
  // background-color:yellow;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;
export const FooterLinkTitle = styled.h1`
  font-size: 22px;
  margin-bottom: 30px;
  text-decoration: underline;
  color: #01BF71;
`;
export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  cursor: pointer;

  &:hover {
    color: #01bf71;
    transition: 0.3s ease-in-out;
  }
  @media screen and (max-width: 420px) {
    font-size: 1rem;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
  margin-bottom: 10px;
`;
export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;
export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;
export const WebsiteRights = styled.small`
  color: #fff;
  margin-bottom: 16px;
`;
export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
`;
export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
`;
