import styled from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  min-height:70%;
  height: auto;
  width: 70%;
  margin: 70px auto;
  text-align: justify;
  font-size: 1.2rem;
  @media screen and ( max-width: 768px ) {
    width:90%;
    min-height: 60vh;
  }
`;

export const InfoSection = styled.div`
  min-height: 80vh;
  
`;

export const PayList = styled.p`
  margin-top: 5px;
  @media screen and ( max-width: 768px ) {
    font-size: 16px;
    @media (min-width: 280px) and (max-width: 320px) {
        font-size: 12px;
      }
  }
`;
export const Title = styled.h3`
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 1.2rem;
  @media (min-width: 280px) and (max-width: 320px) {
    font-size: 16px;
  }
`;
export const TitleTop = styled.h3`
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 3rem;
`;
