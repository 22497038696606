import React from "react";
import { Zoom } from "react-awesome-reveal";
import {
  Container,
  List,
  OrderedList,
  Description,
  Strong,
  Header,
} from "./RegulaminElements";

const Regulamin = () => {
  return (
    <>
      <Container>
        <Zoom triggerOnce='true' >
          <Header>REGULAMIN SKLEPU INTERNETOWEGO</Header>
          <Strong className="headerCenter">www.getloft.pl</Strong>
          <Description></Description>
          <Description>
            <Strong className="headerCenter">§ 1</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">POSTANOWIENIA OGÓLNE</Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                Sklep www.getloft.pl działa na zasadach określonych w niniejszym
                Regulaminie.
              </Description>
            </List>
            <List>
              <Description>
                Regulamin określa warunki zawierania i rozwiązywania Umów
                Sprzedaży Produktu oraz tryb postępowania reklamacyjnego, a
                także rodzaje i zakres usług świadczonych drogą elektroniczną
                przez Sklep www.getloft.pl, zasady świadczenia tych usług,
                warunki zawierania i rozwiązywania umów o świadczenie usług
                drogą elektroniczną.
              </Description>
            </List>
            <List>
              <Description>
                Każdy Usługobiorca z chwilą podjęcia czynności zmierzających do
                korzystania z Usług Elektronicznych Sklepu www.getloft.pl
                zobowiązany jest do przestrzegania postanowień niniejszego
                Regulaminu.
              </Description>
            </List>
            <List>
              <Description>
                W sprawach nieuregulowanych w niniejszym Regulaminie mają
                zastosowanie przepisy:
              </Description>
              <OrderedList>
                <List>
                  <Description>
                    ustawy o świadczeniu usług drogą elektroniczną z dnia 18
                    lipca 2002 r.,
                  </Description>
                </List>
                <List>
                  <Description>
                    ustawy o prawach konsumenta z dnia 30 maja 2014 r.,
                  </Description>
                </List>
                <List>
                  <Description>
                    ustawy o pozasądowym rozwiązywaniu sporów konsumenckich z
                    dnia 23 września 2016 r.,
                  </Description>
                </List>
                <List>
                  <Description>
                    ustawy Kodeks cywilny z dnia 23 kwietnia 1964 r. oraz inne
                    właściwe przepisy prawa polskiego.
                  </Description>
                </List>
              </OrderedList>
            </List>
          </OrderedList>
          <Description>
            <br></br>
          </Description>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 2</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">
              DEFINICJE ZAWARTE W REGULAMINIE
            </Strong>
          </Description>
          <OrderedList>
            {/* <List>
            <Description>
              <Strong>FORMULARZ ZAMÓWIENIA</Strong> – formularz dostępny
              na stronie internetowej www……………..pl umożliwiający złożenie
              Zamówienia.
            </Description>
          </List> */}
            <List>
              <Description>
                <Strong>KLIENT</Strong> – Usługobiorca, który zamierza zawrzeć
                lub zawarł Umowę Sprzedaży ze Sprzedawcą.
              </Description>
            </List>
            <List>
              <Description>
                <Strong>KONSUMENT</Strong> – osoba fizyczna, która dokonuje z
                przedsiębiorcą czynności prawnej niezwiązane bezpośrednio z jej
                działalnością gospodarczą lub zawodową.
              </Description>
            </List>
            <List>
              <Description>
                <Strong>PRZEDSIĘBIORCA </Strong>- osoba fizyczna, osoba prawna i
                jednostka organizacyjna niebędąca osobą prawną, której ustawa
                przyznaje zdolność prawną, prowadząca we własnym imieniu
                działalność gospodarczą lub zawodową.
              </Description>
            </List>
            <List>
              <Description>
                <Strong>PRODUKT</Strong> – dostępna w Sklepie rzecz ruchoma
                będąca przedmiotem Umowy Sprzedaży między Klientem a Sprzedawcą.
              </Description>
            </List>
            <List>
              <Description>
                <Strong>REGULAMIN</Strong> - niniejszy regulamin Sklepu.
              </Description>
            </List>
            <List>
              <Description>
                <Strong>SKLEP</Strong> - Sklep internetowy Usługodawcy
                działający pod adresem www.getloft.pl.
              </Description>
            </List>
            <List>
              <Description>
                <Strong>SPRZEDAWCA</Strong>, <Strong>USŁUGODAWCA</Strong> –
                ………………………………………………………………………………………………
              </Description>
            </List>
            <List>
              <Description>
                <Strong>UMOWA SPRZEDAŻY</Strong> – Umowa Sprzedaży Produktu
                zawarta między Klientem, a Sprzedawcą za pośrednictwem Sklepu.
              </Description>
            </List>
            <List>
              <Description>
                <Strong>ZAMÓWIENIE</Strong> - oświadczenie woli Klienta
                stanowiące ofertę zawarcia Umowy Sprzedaży Produktu ze
                Sprzedawcą.
              </Description>
            </List>
            <List>
              <Description>
                <Strong>CENA </Strong>– wartość wyrażona w jednostkach
                pieniężnych, którą Klient jest obowiązany zapłacić Sprzedawcy za
                Produkt.<br></br>
              </Description>
            </List>
          </OrderedList>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 3</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">
              INFORMACJE DOTYCZĄCE PRODUKTÓW ORAZ ICH ZAMAWIANIA
            </Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                Sklep www.getloft.pl prowadzi sprzedaż Produktów za
                pośrednictwem sieci Internet.
              </Description>
            </List>
            <List>
              <Description>
                Produkty oferowane w Sklepie są nowe, zgodne z umową i zostały
                legalnie wprowadzone na rynek polski.
              </Description>
            </List>
            <List>
              <Description>
                Informacje znajdujące się na stronach internetowych Sklepu nie
                stanowią oferty w rozumieniu przepisów prawa. Klient, składając
                Zamówienie, składa ofertę kupna określonego Produktu na
                warunkach podanych w jego opisie.
              </Description>
            </List>
            <List>
              <Description>
                Cena Produktu uwidoczniona na stronie internetowej Sklepu podana
                jest w złotych polskich (PLN) i zawiera wszystkie składniki.
                Cena nie zawiera kosztów dostawy.
              </Description>
            </List>
            <List>
              <Description>
                Zamówienia można składać poprzez rozmowę telefoniczną / sms /
                email będź messengera. – 24 godziny na dobę przez cały rok.
              </Description>
            </List>
            <List>
              <Description>
                Warunkiem złożenia Zamówienia w Sklepie przez Klienta jest
                zapoznanie się z Regulaminem i akceptacja jego postanowień w
                czasie składania Zamówienia.
              </Description>
            </List>
          </OrderedList>
          <Description>
            <br></br>
          </Description>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 4</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">ZAWARCIE UMOWY SPRZEDAŻY</Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                Do zawarcia Umowy Sprzedaży, niezbędne jest wcześniejsze
                złożenie przez Klienta Zamówienia udostępnionym przez Sprzedawcę
                sposobem, zgodnie z § 3 pkt 5 oraz 6 Regulaminu.
              </Description>
            </List>
            <List>
              <Description>
                Zamówienie uważa się za złożone w momencie zaksiegowania
                zaliczki na koncie sprzedawcy.
              </Description>
            </List>
            <List>
              <Description>
                Potwierdzenie przyjęcia Zamówienia, o którym mowa w pkt 2
                niniejszego paragrafu powoduje związanie Klienta jego
                Zamówieniem.
              </Description>
            </List>
            <List>
              <Description>
                Każda Umowa Sprzedaży będzie potwierdzana dowodem zakupu, który
                będzie dołączany do Produktu.
              </Description>
            </List>
          </OrderedList>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 5</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">SPOSOBY PŁATNOŚCI</Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                Sprzedawca udostępnia następujące sposoby płatności:
              </Description>
              <OrderedList>
                <List>
                  <Description>
                    płatność przelewem tradycyjnym na rachunek bankowy
                    Sprzedawcy,
                  </Description>
                </List>
                <List>
                  <Description>
                    płatność za pośrednictwem elektronicznego systemu płatności-
                    BLIK.
                  </Description>
                </List>
              </OrderedList>
            </List>
            <List>
              <Description>
                W przypadku płatności przelewem tradycyjnym, wpłaty należy
                dokonać na rachunek bankowy numer: 42 1020 5040 0000 6302 0121
                7678 (Bank PKOBP) W tytule przelewu należy wpisać to co zostało
                ustalone podczas podsumowania zamówienia.
              </Description>
            </List>
            <List>
              <Description>
                W każdym przypadku klient dokonuje zapłaty przed rozpoczęciem
                realizacji Zamówienia. Elektroniczny system płatności umożliwia
                dokonanie płatności za pomocą karty kredytowej lub szybkiego
                przelewu z wybranych polskich banków.
              </Description>
            </List>
            <List>
              <Description>
                Klient zobowiązany jest do dokonania zapłaty ceny z tytułu Umowy
                Sprzedaży w terminie (nie dotyczy). dni roboczych od dnia jej
                zawarcia, chyba że Umowa Sprzedaży stanowi inaczej.
              </Description>
            </List>
            <List>
              <Description>
                Produkt zostanie wysłany dopiero po jego opłaceniu.<br></br>
              </Description>
            </List>
          </OrderedList>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 6</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">
              KOSZT, TERMIN I SPOSOBY DOSTAWY PRODUKTU
            </Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                Koszty dostawy Produktu, które pokrywa Klient, są ustalane w
                trakcie procesu składania Zamówienia.
              </Description>
            </List>
            <List>
              <Description>
                Na termin dostawy Produktu składa się czas wykonania Produktu
                oraz czas dostawy Produktu przez przewoźnika:
              </Description>
              <OrderedList>
                <List>
                  <Description>
                    Czas kompletowania Produktów zależy od aktualnej ilości
                    zamówień i jest on podawany w trakcie składania zamówienia,
                  </Description>
                </List>
                <List>
                  <Description>
                    Dostawa Produktów stanowiących rzeczy ruchome przez
                    przewoźnika następuje w terminie przez niego deklarowanym
                    tj. 1-3 ( w szcczególnych wypadkach może to być większa
                    ilość dni) dni roboczych od momentu nadania przesyłki
                    (dostawa następuje wyłącznie w dni robocze z wyłączeniem
                    sobót, niedziel i świąt).
                  </Description>
                </List>
              </OrderedList>
            </List>
            <List>
              <Description>
                Zakupione w Sklepie Produkty są wysyłane wyłącznie na terenie
                Polski za pośrednictwem Poczty Polskiej lub firmy kurierskiej.
              </Description>
            </List>
          </OrderedList>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 7</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">REKLAMACJA PRODUKTU</Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                <Strong>Reklamacja </Strong>
                <Strong>z tytułu braku zgodności Produktu z umową.</Strong>
              </Description>
              <OrderedList>
                <List>
                  <Description>
                    Podstawa i zakres odpowiedzialności Sprzedawcy wobec Klienta
                    będącego Konsumentem z tytułu braku zgodności Produktu z
                    umową są określone w ustawie o prawach konsumenta z dnia z
                    dnia 30 maja 2014 r.,
                  </Description>
                </List>
                <List>
                  <Description>
                    Podstawa i zakres odpowiedzialności Sprzedawcy wobec Klienta
                    będącego Przedsiębiorcą, z tytułu rękojmi są określone w
                    ustawie Kodeks cywilny z dnia 23 kwietnia 1964 r.,
                    <Strong></Strong>
                  </Description>
                </List>
                <List>
                  <Description>
                    Sprzedawca jest odpowiedzialny wobec Klienta będącego
                    Konsumentem za brak zgodności Produktu z umową istniejący w
                    chwili dostarczenia Produktu i ujawniony w ciągu 2 lat od
                    tej chwili, chyba że termin przydatności Produktu określony
                    przez Sprzedawcę lub osoby działające w jego imieniu jest
                    dłuższy,
                  </Description>
                </List>
                <List>
                  <Description>
                    zawiadomienia o braku zgodności Produktu z umową oraz
                    zgłoszenie odpowiedniego żądania można dokonać za
                    pośrednictwem poczty elektronicznej na adres:
                    <Strong> get.lofta@gmail.com</Strong>
                  </Description>
                </List>
                <List>
                  <Description>
                    W powyższej wiadomości w formie pisemnej lub elektronicznej
                    należy podać jak najwięcej informacji i okoliczności
                    dotyczących przedmiotu reklamacji, w szczególności rodzaj i
                    datę wystąpienia nieprawidłowości oraz dane kontaktowe.
                    Podane informacje znacznie ułatwią i przyspieszą
                    rozpatrzenie reklamacji przez Sprzedawcę,
                  </Description>
                </List>
                <List>
                  <Description>
                    Dla oceny nieprawidłowości i niezgodności Produktu z umową,
                    Konsument ma obowiązek udostępnić Produkt Sprzedawcy, a
                    Sprzedawca zobowiązany jest do odebrania go na swój koszt,
                  </Description>
                </List>
                <List>
                  <Description>
                    Sprzedawca ustosunkuje się do żądania Klienta niezwłocznie,
                    nie później niż w terminie 14 dni od momentu zgłoszenia
                    reklamacji,
                  </Description>
                </List>
                <List>
                  <Description>
                    W przypadku reklamacji Klienta będącego Konsumentem –
                    nierozpatrzenie reklamacji w terminie 14 dni od jej
                    zgłoszenia jest jednoznaczne z jej uwzględnieniem,
                  </Description>
                </List>
                <List>
                  <Description>
                    w związku z uzasadnioną reklamacją Klienta będącego
                    Konsumentem Sprzedawca odpowiednio:
                  </Description>
                </List>
              </OrderedList>
            </List>
          </OrderedList>
          <OrderedList type="a">
            <List>
              <Description>
                pokrywa koszty naprawy lub wymiany oraz ponownego dostarczenia
                Produktu do Klienta,
              </Description>
            </List>
            <List>
              <Description>
                Obniża cenę Produktu (obniżona cena musi pozostawać w proporcji
                ceny towaru zgodnego z umową do towaru niezgodnego z umową) i
                zwraca Konsumentowi wartość obniżonej ceny najpóźniej w terminie
                14 dni od otrzymania oświadczenia o obniżeniu ceny od
                Konsumenta,
              </Description>
            </List>
            <List>
              <Description>
                W przypadku odstąpienia od umowy przez Konsumenta – Sprzedawca
                zwraca mu cenę Produktu najpóźniej w terminie 14 dni od dnia
                otrzymania zwracanego towaru lub dowodu jego odesłania. W razie
                odstąpienia od umowy, Konsument zobowiązany jest niezwłocznie
                zwrócić towar do Sprzedawcy na koszt Sprzedawcy,
              </Description>
            </List>
          </OrderedList>
          <OrderedList>
            <OrderedList start="10">
              <List>
                <Description>
                  Odpowiedź na reklamację jest przekazywana na papierze lub
                  innym trwałym nośniku np. wiadomość mailowa lub SMS.
                </Description>
              </List>
            </OrderedList>
          </OrderedList>
          <Description>
            <br></br>
          </Description>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 8</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">PRAWO ODSTĄPIENIA OD UMOWY</Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                Z zastrzeżeniem pkt 10 niniejszego paragrafu, Klient będący
                jednocześnie Konsumentem, który zawarł umowę na odległość, może
                od niej odstąpić bez podania przyczyn, składając stosowne
                oświadczenie w terminie 14 dni.
              </Description>
            </List>
            <List>
              <Description>
                W razie odstąpienia od umowy, Umowa Sprzedaży jest uważana za
                niezawartą, a Konsument ma obowiązek zwrócić Produkt Sprzedawcy
                lub przekazać go osobie upoważnionej przez Sprzedawcę do odbioru
                niezwłocznie, jednak nie później niż 14 dni od dnia, w którym
                odstąpił od umowy, chyba że Sprzedawca zaproponował, że sam
                odbierze Produkt. Do zachowania terminu wystarczy odesłanie
                Produktu przed jego upływem.
              </Description>
            </List>
            <List>
              <Description>
                W przypadku odstąpienia od Umowy Sprzedaży należy dokonać zwrotu
                Produktu na adres: 86-300 Grudziądz ul. Droga Bociania 5a
              </Description>
            </List>
            <List>
              <Description>
                Konsument ponosi odpowiedzialność za zmniejszenie wartości
                Produktu będące wynikiem korzystania z niego w sposób
                wykraczający poza konieczny do stwierdzenia charakteru, cech i
                funkcjonowania Produktu, chyba że Sprzedawca nie poinformował
                Konsumenta lub podmiotu, o którym mowa w § 10 o sposobie i
                terminie wykonania prawa odstąpienia od umowy oraz nie
                udostępnił mu wzoru formularza odstąpienia od umowy. W celu
                stwierdzenia charakteru, cech i funkcjonowania Produktów
                Konsument powinien obchodzić się z Produktami i sprawdzać je
                tylko w taki sam sposób, w jaki mógłby to zrobić w sklepie
                stacjonarnym.
              </Description>
            </List>
            <List>
              <Description>
                Z zastrzeżeniem pkt 6 oraz 8 niniejszego paragrafu, Sprzedawca
                dokona zwrotu wartości Produktu wraz z kosztami jego dostawy
                przy użyciu takiego samego sposobu zapłaty, jakiego użył
                Konsument, chyba że Konsument wyraźnie zgodził się na inny
                sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami.
                Z zastrzeżeniem pkt 7 niniejszego paragrafu, zwrot nastąpi
                niezwłocznie, a najpóźniej w terminie 14 dni od momentu
                otrzymania przez Sprzedawcę oświadczenia o odstąpieniu od Umowy
                Sprzedaży.
              </Description>
            </List>
            <List>
              <Description>
                Jeżeli Konsument wybrał sposób dostawy Produktu inny niż
                najtańszy zwykły sposób dostarczenia oferowany przez Sklep,
                Sprzedawca nie jest zobowiązany do zwrotu mu, poniesionych przez
                niego dodatkowych kosztów.
              </Description>
            </List>
            <List>
              <Description>
                Jeżeli Sprzedawca nie zaproponował, że sam odbierze Produkt od
                Konsumenta może wstrzymać się ze zwrotem płatności otrzymanych
                od Konsumenta do chwili otrzymania rzeczy z powrotem lub
                dostarczenia dowodu jej odesłania, w zależności od tego, które
                zdarzenie nastąpi wcześniej.
              </Description>
            </List>
            <List>
              <Description>
                Konsument odstępujący od Umowy Sprzedaży, zgodnie z pkt 1
                niniejszego paragrafu, ponosi jedynie koszty odesłania Produktu
                do Sprzedawcy.
              </Description>
            </List>
            <List>
              <Description>
                Termin czternastodniowy, w którym Konsument może odstąpić od
                umowy, liczy się dla umowy, w wykonaniu której Sprzedawca wydaje
                Produkt będąc zobowiązanym do przeniesienia jego własności - od
                dnia, w którym Konsument (lub wskazana przez niego osoba trzecia
                inna niż przewoźnik) objął Produkt w posiadanie.
              </Description>
            </List>
            <List>
              <Description>
                Prawo odstąpienia od umowy zawartej na odległość nie przysługuje
                Konsumentowi w wypadku Umowy Sprzedaży m.in., w której
                przedmiotem świadczenia jest towar nieprefabrykowany,
                wyprodukowany według specyfikacji Konsumenta lub służący
                zaspokojeniu jego zindywidualizowanych potrzeb.
              </Description>
            </List>
            <List>
              <Description>
                Prawo odstąpienia od Umowy Sprzedaży przysługuje zarówno
                Sprzedawcy, jak i Klientowi, w przypadku niewykonania przez
                drugą stronę umowy swojego zobowiązania w terminie ściśle
                określonym.
              </Description>
            </List>
          </OrderedList>
          <Description>
            <br></br>
          </Description>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 9</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">
              RODZAJ I ZAKRES USŁUG ELEKTRONICZNYCH
            </Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                Usługodawca umożliwia za pośrednictwem Sklepu korzystanie z
                Usługi Elektronicznej jaką jest zawieranie Umów Sprzedaży
                Produktu.
              </Description>
            </List>
            <List>
              <Description>
                Świadczenie Usługi Elektronicznej na rzecz Usługobiorców w
                Sklepie odbywa się na warunkach określonych w Regulaminie.
              </Description>
            </List>
            <List>
              <Description>
                Usługodawca ma prawo do zamieszczania na stronie internetowej
                Sklepu treści reklamowych. Treści te, stanowią integralną część
                Sklepu i prezentowanych w nim materiałów.
              </Description>
            </List>
          </OrderedList>
          <Description>
            <br></br>
          </Description>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 10</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">
              WARUNKI ŚWIADCZENIA I ZAWIERANIA UMÓW O ŚWIADCZENIE USŁUG
              ELEKTRONICZNYCH
            </Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                Świadczenie Usługi Elektronicznej określonej w § 9 pkt 1
                Regulaminu przez Usługodawcę jest nieodpłatne.
              </Description>
            </List>
            <List>
              <Description>
                Umowa o świadczenie Usługi Elektronicznej polegającej na
                umożliwieniu złożenia Zamówienia w Sklepie zawierana jest na
                czas oznaczony i ulega rozwiązaniu z chwilą złożenia Zamówienia
                albo zaprzestania jego składania przez Usługobiorcę.
              </Description>
            </List>
            <List>
              <Description>
                Wymagania techniczne niezbędne do współpracy z systemem
                teleinformatycznym, którym posługuje się Usługodawca:
              </Description>
              <OrderedList>
                <List>
                  <Description>
                    komputer (lub urządzenie mobilne) z dostępem do Internetu,
                  </Description>
                </List>
                <List>
                  <Description>dostęp do poczty elektronicznej,</Description>
                </List>
                <List>
                  <Description> przeglądarka internetowa,</Description>
                </List>
                <List>
                  <Description>
                    włączenie w przeglądarce internetowej Cookies oraz
                    Javascript.
                  </Description>
                </List>
              </OrderedList>
            </List>
            <List>
              <Description>
                Usługobiorca zobowiązany jest do korzystania ze Sklepu w sposób
                zgodny z prawem i dobrymi obyczajami mając na uwadze
                poszanowanie dóbr osobistych i praw własności intelektualnej
                osób trzecich.
              </Description>
            </List>
            <List>
              <Description>
                Usługobiorca zobowiązany jest do wprowadzania danych zgodnych ze
                stanem faktycznym.
              </Description>
            </List>
          </OrderedList>
          <OrderedList>
            <List>
              <Description>
                Usługobiorcę obowiązuje zakaz dostarczania treści o charakterze
                bezprawnym.
              </Description>
            </List>
          </OrderedList>
          <Description>
            <br></br>
          </Description>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 11</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">
              REKLAMACJE ZWIĄZANE ZE ŚWIADCZENIEM USŁUG ELEKTRONICZNYCH
            </Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                Reklamacje związane ze świadczeniem Usługi Elektronicznej za
                pośrednictwem Sklepu Usługobiorca może składać za pośrednictwem
                poczty elektronicznej na adres: get.lofta@gmail.com
              </Description>
            </List>
            <List>
              <Description>
                W powyższej wiadomości e-mail, należy podać jak najwięcej
                informacji i okoliczności dotyczących przedmiotu reklamacji, w
                szczególności rodzaj i datę wystąpienia nieprawidłowości oraz
                dane kontaktowe. Podane informacje znacznie ułatwią i
                przyspieszą rozpatrzenie reklamacji przez Usługodawcę.
              </Description>
            </List>
            <List>
              <Description>
                Rozpatrzenie reklamacji przez Usługodawcę następuje
                niezwłocznie, nie później niż w terminie 14 dni od momentu
                zgłoszenia.
              </Description>
            </List>
            <List>
              <Description>
                Odpowiedź Usługodawcy w sprawie reklamacji jest wysyłana na
                adres e-mail Usługobiorcy podany w zgłoszeniu reklamacyjnym lub
                w inny podany przez Usługobiorcę sposób
              </Description>
            </List>
          </OrderedList>
        </Zoom>
        <Zoom triggerOnce='true'>
          <Description>
            <Strong className="headerCenter">§ 12</Strong>
          </Description>
          <Description>
            <Strong className="headerCenter">POSTANOWIENIA KOŃCOWE</Strong>
          </Description>
          <OrderedList>
            <List>
              <Description>
                Umowy zawierane poprzez Sklep zawierane są zgodnie z prawem
                polskim.
              </Description>
            </List>
            <List>
              <Description>
                W przypadku niezgodności jakiejkolwiek części Regulaminu z
                obowiązującym prawem, w miejsce zakwestionowanego przepisu
                Regulaminu zastosowanie mają właściwe przepisy prawa polskiego.
              </Description>
            </List>
            <List>
              <Description>
                Wszelkie spory wynikłe z Umów Sprzedaży między Sklepem, a
                Konsumentami będą rozstrzygane w pierwszej kolejności na drodze
                negocjacji, z intencją polubownego zakończenia sporu, z
                uwzględnieniem ustawy o pozasądowym rozwiązywaniu sporów
                konsumenckich. Jeśli jednak nie byłoby to możliwe, lub też
                byłoby niesatysfakcjonujące dla którejkolwiek ze stron, spory
                będą rozstrzygane przez właściwy sąd powszechny, zgodnie z pkt 4
                niniejszego paragrafu.
              </Description>
            </List>
            <List>
              <Description>
                Ewentualne spory powstałe pomiędzy Usługodawcą, a Usługobiorcą
                (Klientem) będącym jednocześnie Konsumentem zostają poddane
                sądom właściwym zgodnie z przepisami kodeksu postępowania
                cywilnego z dnia 17 listopada 1964 r.
              </Description>
            </List>
            <List>
              <Description>
                Klient będący Konsumentem ma również prawo do skorzystania z
                pozasądowych sposobów rozstrzygania sporów w szczególności
                poprzez złożenie po zakończeniu postępowania reklamacyjnego
                wniosku o wszczęcie mediacji lub wniosku o rozpatrzenie sprawy
                przez sąd polubowny (wniosek można pobrać na stronie
                internetowej
                <a href="http://www.uokik.gov.pl/download.php?plik=6223)">
                  http://www.uokik.gov.pl/download.php?plik=6223)
                </a>
                . Wykaz Stałych Polubownych Sądów Konsumenckich działających
                przy Wojewódzkich Inspektoratach Inspekcji Handlowej dostępny
                jest na stronie internetowej:
                http://www.uokik.gov.pl/wazne_adresy.php#faq596. Konsument może
                skorzystać także z bezpłatnej pomocy powiatowego (miejskiego)
                rzecznika konsumentów lub organizacji społecznej, do której
                zadań statutowych należy ochrona konsumentów. Pozasądowe
                dochodzenie roszczeń po zakończeniu postępowania reklamacyjnego
                jest bezpłatne.
              </Description>
            </List>
            <List>
              <Description>
                Konsument w celu polubownego rozwiązania sporu może w
                szczególności złożyć skargę za pośrednictwem platformy
                internetowej ODR (Online Dispute Resolution), dostępnej pod
                adresem:
                <a href="http://ec.europa.eu/consumers/odr">
                  http://ec.europa.eu/consumers/odr
                </a>
                /.
              </Description>
            </List>
          </OrderedList>
        </Zoom>
        <Description></Description>
      </Container>
    </>
  );
};

export default Regulamin;
