import styled from "styled-components";

export const InfoContainer = styled.div`
  color: #010606ed;
  background: ${({ lightBg }) => (lightBg ? "#f9f9f9" : "#262626")};
  // min-height: 100vh;
  height:auto;

  scroll-snap-align: start;
  scroll-snap-stop: always;
  overflow-x: hidden;

  
  -webkit-box-shadow: 8px 8px 51px 16px rgba(66, 68, 90, 1);
  -moz-box-shadow: 8px 8px 51px 16px rgba(66, 68, 90, 1);
  box-shadow: 8px 8px 51px 16px rgba(66, 68, 90, 1);

  transition: box-shadow 0.3s ease-in-out;

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;
export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 100vh;
  width: 100%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  
  @media screen and (max-width: 768px) {
    height: auto;
    width:100%;
  }
`;
export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;
export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;
export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;
export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;
export const TopLine = styled.p`
  color: #01bf71;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;
export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 1.9rem;
  line-height: 1.1;
  font-weight: 600;

  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#262626")};

  @media screen and (max-width: 480) {
    font-size: 14px;
  }
  @media (min-width: 280px) and (max-width: 320px) {
    font-size: 1rem;
    text-align: justify;
    text-justify: inter-word;
  }
`;
export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 1.1rem;
  line-height: 24px;
  text-align: justify;
  text-justify: inter-word;
  color: ${({ darkText }) => (darkText ? "#010606" : "#fff")};
  
  @media (min-width: 280px) and (max-width: 320px) {
    font-size: 0.7rem;
  }
`;
export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;
export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;
export const Img = styled.img`
  border-radius: 10px;
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
`;
