import React from "react";
import { Zoom } from "react-awesome-reveal";
import {
  MainContainer,
  ContainerOne,
  ContainerTwo,
  ContainerThree,
  ContainerFour,
  Wymiar,
  WymiarDane,
  StandardowaCena,
  StandardowaCenaDane,
  CenaBoki,
  CenaBokiDane,
  Rasy,
  RasyDane,
  Button,
  ButtonWrap,
  PriceWrapper,
} from "./PricesTableElements";

const PricesTable = ({
  id,
  sizeName,
  size,
  priceOneName,
  priceOneData,
  priceTwoName,
  priceTwoData,
  rasaName,
  rasaDescription,
  description,
  linkTo,
}) => {
  return (
    <>
      <MainContainer>
    <Zoom cascade>
        <PriceWrapper id={id}>
          
          <ContainerOne>
            <Wymiar>{sizeName}</Wymiar>
            <WymiarDane>{size}</WymiarDane>
          </ContainerOne>
          <ContainerTwo>
            <StandardowaCena>{priceOneName}</StandardowaCena>
            <StandardowaCenaDane>{priceOneData}</StandardowaCenaDane>
          </ContainerTwo>
          <ContainerThree>
            <CenaBoki>{priceTwoName}</CenaBoki>
            <CenaBokiDane>{priceTwoData}</CenaBokiDane>
          </ContainerThree>
          <ContainerFour>
            <Rasy>{rasaName}</Rasy>
            <RasyDane>{rasaDescription}</RasyDane>
          </ContainerFour>
          <ButtonWrap>
            <Button to={linkTo} exact="true">
              Pokaż
            </Button>
          </ButtonWrap>
        </PriceWrapper>
        {description}
      </Zoom>
      </MainContainer>
    </>
  );
};

export default PricesTable;
