import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 100px auto;
  width: 70%;
  font-size: 1rem;
  text-align: justify;
  line-height:30px;
  @media screen and ( max-width: 768px ) {
    width:90%;
    font-size: 14px;
    overflow-wrap: break-word;
    line-height: 18px;
  }
`;
export const List = styled.li`
  text-align: -webkit-match-parent;
  margin-left: 40px;
  @media screen and ( max-width: 768px ) {
    margin-left:0;
  }
`;
export const OrderedList = styled.ol`
  list-style-type: decimal;

`;
export const Description = styled.p`
  font-size: 18px;
  @media screen and ( max-width: 768px ) {
    font-size: 14px;
  }
`;
export const Strong = styled.strong`
  text-align: center;
  font-weight: bold;
`;
export const Header = styled.h1`
  margin: 40px auto;
  text-align: center;
  @media screen and ( max-width: 768px ) {
    line-height: 30px;
  }
`;
