import { Carousel } from 'react-carousel-minimal';
import image1 from "../../images/duo/duo (1).jpg";
import image2 from "../../images/duo/duo (2).jpg";
import image3 from "../../images/duo/duo (3).jpg";
import image4 from "../../images/duo/duo (4).jpg";
import image5 from "../../images/duo/duo (5).jpg";
import image6 from "../../images/duo/duo (6).jpg";
import image7 from "../../images/duo/duo (7).jpg";
import image8 from "../../images/duo/duo (8).jpg";
import image9 from "../../images/duo/duo (9).jpg";
import image10 from "../../images/duo/duo (10).jpg";

function Galeria() {
 const data = [
    {
      image: image1,
      caption: "podwójna drewniana klatka kennelowa z dębowym blatem",
    },
    {
      image: image2,
      caption: "Dwie białe klatki kennelowe z przesuwnymi drzwiami",
    },
    {
      image:image3,
      caption: "Szare drewniane klatki kenelowe",
    },
    {
      image: image4,
      caption: "Białe drewniane klatki kennelowe z dębowym blatem",
    },
    {
      image: image5,
      caption: "Czarne klatki kennelowe z dębowym blatem halifax",
    },
    {
      image: image6,
      caption: "Zestaw drewnianych klatek kennelowych czarnych",
    },
    {
      image: image7,
      caption: "potrójna drewniana klatka kennelowa biała dla psów",
    },
    {
      image: image8,
      caption: "Podwójna drewniana klatka kennelowa w kolorze kasztan ",
    },
    {
      image: image9,
      caption: "Narożna podwójna drewniana klatka kennelowa w kolorze naturalnej sosny",
    },
    {
      image: image10,
      caption: "Podwójna drewnianna klatka kennelowa z szufladami w kolorze palisandru",
    },
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App">
      <div style={{ 
        textAlign: "center",
        marginTop: "100px",
        marginBottom: "300px"
        }}>
        <h2
        style={{
          textAlign: "center",
          maxWidth: "850px",
          maxHeight: "500px",
          margin: "50px auto ",
          paddingTop: "50px"
        }}>Zestawy podwójne - czyli DuoKennel</h2>
        <p>Ze względu na zapotrzebowanie na duże klatki podwójne - dla dwóch i więcej piesków, wyszliśmy z propozycją podwójnego kenela - DuolKennel.</p>
        <p>Proponujemy rozwiązanie dwóch klatek ze względu na to, że klatka podwójna w jednej bryle stanowi wyzwanie w sferze jej dostarczenia oraz ciężaru.</p>
        <p>Nie ukrywajmy - klatki są wykonane z litego / klejonego drewna oraz metalu, stąd nasza propozycja.</p>
        <p>Są one projektowane w taki sposób by na łączniu nie było progu - jest to według nas bardziej estetyczne i daje te kilka cm więcej dla pieska.</p>
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
            data={data}
            time={5000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "100px auto ",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Galeria;
