import React from "react";
import { Zoom } from "react-awesome-reveal";
import {
  InfoSection,
  TitleTop,
  MainContainer,
  PayList,
} from "../StyleElements";

const Soon = () => {
  return (
    <>
      <Zoom>
        <MainContainer>
          <InfoSection>
            <TitleTop>Zapraszamy wkrótce !!</TitleTop>
            <PayList>
              Ale ale !! To tylko dział w budowie, oczywiście nasze stojaczki
              i legowiska mozna swobodnie zamawiać - wystarczy zadzwonić /
              napisać i pokazać który model ( znajdziesz je na naszej stronie np
              na OLX )
            </PayList>
          </InfoSection>
        </MainContainer>
      </Zoom>
    </>
  );
};

export default Soon;
