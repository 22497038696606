import React, { useState } from "react";
import Image from "../../images/collage.jpg";
import { Button } from "../ButtonElement";
import {
  HeroContainer,
  HeroBg,
  ImageBg,
  HeroContent,
  HeroH1,
  HeroP,
  ArrowForward,
  ArrowRight,
  HeroBtnWrapper,
} from "./HeroElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer className="scrollMe" >
      <HeroBg>
        <ImageBg src={Image} alt="mixed collage" type="image/jpg" />
      </HeroBg>
      <HeroContent>
        <HeroH1>My tworzymy </HeroH1>
        <HeroP>Wspaniałe Meble i akcesoria z drewna dla Waszych piesków</HeroP>
        <HeroBtnWrapper>
          <Button
            big="true"
            to="/klatki-kennelowe-cennik"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
          >
            Zamów {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
