import { Carousel } from 'react-carousel-minimal';
import image1 from "../../images/szuf/szuf (1).jpg";
import image2 from "../../images/szuf/szuf (2).jpg";
import image3 from "../../images/szuf/szuf (3).jpg";
import image4 from "../../images/szuf/szuf (4).jpg";
import image5 from "../../images/szuf/szuf (5).jpg";
import image6 from "../../images/szuf/szuf (6).jpg";
import image7 from "../../images/szuf/szuf (7).jpg";
import image8 from "../../images/szuf/szuf (8).jpg";
import image9 from "../../images/szuf/szuf (9).jpg";
import image10 from "../../images/szuf/szuf (10).jpg";

function Galeria() {
 const data = [
    {
      image: image1,
      caption: "Mała biała drewniana klatka kennelowa z szufladą",
    },
    {
      image: image2,
      caption: "Biała klatka kennelowa dla średniego psa z szufladami",
    },
    {
      image:image3,
      caption: "Drewniana klatka kennelowa z szafką w kolorze białym",
    },
    {
      image: image4,
      caption: "Drewniana klatka kennelowa z szufladami i szafką w kolorze białym.",
    },
    {
      image: image5,
      caption: "Drewniana klatka kennelowa z szufladami i szafką w kolorze białym",
    },
    {
      image: image6,
      caption: "Drewniana klatka kennelowa z szufladami i szafką w kolorze białym",
    },
    {
      image: image7,
      caption: "Podwójna klatka kennelowa dla małych piesków z szufladami",
    },
    {
      image: image8,
      caption: "Podwójna klatka kennelowa dla małych piesków z szufladami",
    },
    {
      image: image9,
      caption: "Duża drewniana klatka kennelowa z szufladami w kolorze palisandru",
    },
    {
      image: image10,
      caption: "Duża drewniana klatka kennelowa z szufladami w kolorze ciemnego drewna",
    },
  ];

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div className="App">
      <div style={{ 
        textAlign: "center",
        marginTop: "100px",
        marginBottom: "300px"
        }}>
        <h2
        style={{
          textAlign: "center",
          maxWidth: "850px",
          maxHeight: "500px",
          margin: "50px auto ",
          paddingTop: "50px"
        }}>Klatki kenelowe z szufladami / szafkami</h2>
        <p>Poniższe modele klatek kenelowych jest produkowany wyłącznie na podstawie wymiarów podanych przez klienta</p>
        <p>Szuflady montujemy na prowadnicach z cichym domykiem firmy GTV, a zawiasy montowane w szafkach </p>
        <p> są to najwyższej jakości zawiasy firmy Blum - również z cichym domykiem które w razie potrzeby można wypinać i włączać i wyłączać wolny domyk.</p>
        <p>Jeśli chodzi o cenę takich kombinacji to są one wyceniane indywidualnie.</p>
        <div style={{
          padding: "0 20px"
        }}>
          <Carousel
            data={data}
            time={5000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="bottom"
            automatic={true}
            dots={true}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor="darkgrey"
            slideImageFit="cover"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: "center",
              maxWidth: "850px",
              maxHeight: "500px",
              margin: "100px auto ",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Galeria;
